import React from 'react';
import { navigate } from 'gatsby';
import { notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isLoggedIn } from '../services/auth';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    notification.open({
      message: 'Restricted content area.',
      description: 'Please login and try again. Thank you!',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
    });
    navigate('/portal/login');
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
