import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Form, Input, Button, notification } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { handleLogin, isLoggedIn } from '../services/auth';

import Styles from './login.module.css';
import Logo from '../images/ekko-logo.jpg';

const Login = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = ({ email, password }) => {
    setLoading(true);
    const loginStatus = handleLogin(email, password);

    if (loginStatus === false) {
      notification.open({
        message: 'Invalid Login Credentials',
        description:
          'Please try logging in with your email and password! Thank you.',
        icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      });
      return setLoading(false);
    }

    setTimeout(() => navigate(`/portal/content`), 2000);
  };

  if (isLoggedIn()) navigate(`/portal/content`);

  return (
    <div className={Styles.container}>
      <Form className={Styles.form} name="login" onFinish={onFinish}>
        <img src={Logo} alt="Ekko Logo" className={Styles.logo} />
        <div className={Styles.title}>Member Login</div>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input email address.' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email Address"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter your password.' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button className={Styles.btn} htmlType="submit" loading={loading}>
            LOGIN
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
