import React from 'react';
import { Router } from '@reach/router';
import SEO from '../components/seo';
import Layout from '../components/layout';
import PrivateRoute from '../components/privateRoute';
import Login from '../components/login';
import Content from '../components/content';

const Portal = () => (
  <>
    <SEO title="Portal" />
    <Layout>
      <Router>
        <PrivateRoute path="/portal/content/" component={Content} />
        <Login path="/portal/login/" />
      </Router>
    </Layout>
  </>
);
export default Portal;
