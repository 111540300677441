import React from 'react';
import Parenting from '../components/content/parenting';
import Selah from '../components/content/selah';
import Work from '../components/content/work';
import Doulos from '../components/content/doulos';
import Logos from '../components/content/logos';
import Vocation from '../components/content/vocation';
import Pneuma from '../components/content/pneuma';
import Marriage from '../components/content/marriage';

import { isLoggedIn } from '../services/auth';

const Content = () => {
  const currentCourse = JSON.parse(localStorage.getItem('advanceUser')).course;

  if (isLoggedIn() && currentCourse === 'Selah') {
    return <Selah />;
  }
  if (isLoggedIn() && currentCourse === 'Parenting') {
    return <Parenting />;
  }
  if (isLoggedIn() && currentCourse === 'Work') {
    return <Work />;
  }
  if (isLoggedIn() && currentCourse === 'Logos') {
    return <Logos />;
  }
  if (isLoggedIn() && currentCourse === 'Vocation') {
    return <Vocation />;
  }
  if (isLoggedIn() && currentCourse === 'Doulos') {
    return <Doulos />;
  }
  if (isLoggedIn() && currentCourse === 'Pneuma') {
    return <Pneuma />;
  }
  if (isLoggedIn() && currentCourse === 'Marriage') {
    return <Marriage />;
  }
};

export default Content;
