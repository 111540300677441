import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeroMarriage from '../image-components/hero/heroMarriage';
import Styles from './content.module.css';

const Marriage = () => {
  const [showWeek0, setWeek0] = useState(false);
  const [showWeek1, setWeek1] = useState(false);
  const [showWeek2, setWeek2] = useState(false);
  const [showWeek3, setWeek3] = useState(false);
  const [showWeek4, setWeek4] = useState(false);
  const [showWeek5, setWeek5] = useState(false);
  const [showWeek6, setWeek6] = useState(false);

  const modalClose = () => {
    setWeek0(false);
    setWeek1(false);
    setWeek2(false);
    setWeek3(false);
    setWeek4(false);
    setWeek5(false);
    setWeek6(false);
  };
  return (
    <>
      <HeroMarriage />
      <div className={Styles.container}>
        <div className={Styles.title}>MARRIAGE DASHBOARD</div>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <a
              href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Marriage%20Advance%20Syllabus%20F2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={Styles.syllabuscard}>SYLLABUS</button>
            </a>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek0(true)}>
              WEEK 0
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek1(true)}>
              WEEK 1
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek2(true)}>
              WEEK 2
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek3(true)}>
              WEEK 3
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek4(true)}>
              WEEK 4
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek5(true)}>
              WEEK 5
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek6(true)}>
              WEEK 6
            </button>
          </Col>
        </Row>

        <Modal
          visible={showWeek0}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 0</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/What%20is%20the%20Heart%20of%20Marriage_Ash.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "What is the Heart of Marriage?" by Christopher Ash (from
                  Married for God)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>
              Prepare to Share at the First Session
            </h2>
            <ol>
              <li>
                <p>
                  Write your version of your love story. How did you meet, when
                  did you know she/he was the one? What’s one funny story from
                  your dating years?
                </p>
              </li>
              <li>
                <p>
                  Reflect on these questions: what was the most life giving
                  thing about your dating season? What was the hardest or the
                  most life draining thing about your dating season?
                </p>
              </li>
            </ol>
          </div>
        </Modal>
        <Modal
          visible={showWeek1}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 1</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/The%20Secret%20of%20Marriage_Keller.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Secret of Marriage" by Tim Keller (from The Meaning
                  of Marriage)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/The%20Power%20of%20Marriage_Keller.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Power of Marriage" by Tim Keller (from The Meaning
                  of Marriage)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/The%20Essence%20of%20Marriage_Keller.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Essence of Marriage" by Tim Keller (from The Meaning
                  of Marriage)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Response</h2>
            <ol>
              <li>
                Write a 250 word response to the readings and submit them to the
                Google Group by Wednesday at 11:59PM.
              </li>
              <li>
                Respond to another post as well as responses to your own post by
                Friday at 5:00PM.
              </li>
              <li>Have your Examens ready to show at Advance.</li>
            </ol>
          </div>
        </Modal>
        <Modal
          visible={showWeek2}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 2</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                Read "The Pain Cycle" by Terry Hargrave & Shawn Stoever (from 5
                Days to a New Marriage)
              </li>
              <li>
                Read "The Peace Cycle" by Terry Hargrave & Shawn Stoever (from 5
                Days to a New Marriage)
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Response</h2>
            <ol>
              <li>
                Write a 250 word response to the readings and submit them to the
                Google Group by Wednesday at 11:59PM.
              </li>
              <li>
                Respond to another post as well as responses to your own post by
                Friday at 5:00PM.
              </li>
              <li>
                If you feel comfortable, include your Pain & Peace Cycles in
                your response to your group.
              </li>
              <li>Have your Examens ready to show at Advance.</li>
            </ol>
          </div>
        </Modal>
        <Modal
          visible={showWeek3}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 3</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <div style={{ fontStyle: 'italic' }}>Read and respond by 9/22:</div>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/A%20Day%20in%20a%20Boundaryless%20Life_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "A Day in a Boundaryless Life" by Harry Cloud & John
                  Townsend (from Boundaries)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/What%20Does%20a%20Boundary%20Look%20Like_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "What Does a Boundary Look Like?" by Harry Cloud & John
                  Townsend (from Boundaries)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Boundary%20Problems_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Boundary Problems" by Harry Cloud & John Townsend (from
                  Boundaries)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/How%20Boundaries%20Are%20Developed_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "How Boundaries are Developed" by Harry Cloud & John
                  Townsend (from Boundaries)
                </a>
              </li>
            </ul>
            <div style={{ fontStyle: 'italic' }}>Read and respond by 9/29:</div>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Ten%20Laws%20of%20Boundaries_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Ten Laws of Boundaries" by Harry Cloud & John Townsend
                  (from Boundaries)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Common%20Boundary%20Myths_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Common Boundary Myths" by Harry Cloud & John Townsend
                  (from Boundaries)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Boundaries%20and%20Your%20Family_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Boundaries and Your Family" by Harry Cloud & John
                  Townsend (from Boundaries)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Boundaries%20and%20Your%20Spouse_Cloud.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Boundaries and Your Spouse" by Harry Cloud & John
                  Townsend (from Boundaries)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Response</h2>
            <ol>
              <li>
                Write a 250 word response to the readings and submit them to the
                Google Group by Wednesday at 11:59PM.
              </li>
              <li>
                Respond to another post as well as responses to your own post by
                Friday at 5:00PM.
              </li>
              <li>Have your Examens ready to show at Advance.</li>
            </ol>
          </div>
        </Modal>
        <Modal
          visible={showWeek4}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 4</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                Read "’Us-Ness’" by Terry Hargrave & Shawn Stoever (from 5 Days
                to a New Marriage)
              </li>
              <li>
                Read "A Different Future" by Terry Hargrave & Shawn Stoever
                (from 5 Days to a New Marriage)
              </li>
              <li>
                Read "Your New Marriage—Forever" by Terry Hargrave & Shawn
                Stoever (from 5 Days to a New Marriage)
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Response</h2>
            <ol>
              <li>
                Write a 250 word response to the readings and submit them to the
                Google Group by Wednesday at 11:59PM.
              </li>
              <li>
                Respond to another post as well as responses to your own post by
                Friday at 5:00PM.
              </li>
            </ol>
          </div>
        </Modal>
        <Modal
          visible={showWeek5}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 5</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>Watch the video on the Vocational Credo.</li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Gottman_Four%20Keys.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read excerpts from "The Four Keys to Improving Your Marriage"
                  by John Gottman (from Why Marriages Succeed or Fail)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Gottman_Your%20Private%20Thoughts.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read excerpts from "Your Private Thoughts Become Cast in
                  Stone" by John Gottman (from Why Marriages Succeed or Fail)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Response</h2>
            <ol>
              <li>
                Write a 250 word response to the readings and submit them to the
                Google Group by Wednesday at 11:59PM.
              </li>
              <li>
                Respond to another post as well as responses to your own post by
                Friday at 5:00PM.
              </li>
              <li>Have your Examens ready to show at Advance.</li>
            </ol>
          </div>
        </Modal>
        <Modal
          visible={showWeek6}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 6</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/The%20Mission%20of%20Marriage_Keller.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Mission of Marriage" by Tim Keller (from The Meaning
                  of Marriage)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Introduction%20to%20the%209%20Types_Schneider.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Introduction to the Nine Types" by Jennifer P. Schneider
                  (from Understand Yourself, Understand Your Partner)
                </a>
              </li>
              <li>
                Read the chapter pertaining to your Enneagram type.
                <ul style={{ columns: 2 }}>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%201_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 1
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%202_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 2
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%203_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 3
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%204_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 4
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%205_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 5
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%206_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%207_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%208_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Type%209_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Type 9
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Read the chapter pertaining to your partner’s Enneagram type.
                <ul style={{ columns: 5 }}>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-1%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 1
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-2%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 2
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-3%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 3
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-4%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 4
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-5%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 5
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-6%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-7%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/1-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      1 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-2%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 2
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-3%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 3
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-4%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 4
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-5%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 5
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-6%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-7%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/2-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/3-3%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 - 3
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/3-4%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 - 4
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/3-5%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 - 5
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/3-6%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 - 6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/3-7%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 - 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/3-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/3-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/4-4%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      4 - 4
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/4-5%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      4 - 5
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/4-6%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      4 - 6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/4-7%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      4 - 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/4-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      4 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/4-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      4 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/5-5%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      5 - 5
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/5-6%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      5 - 6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/5-7%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      5 - 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/5-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      5 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/5-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      5 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/6-6%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      6 - 6
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/6-7%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      6 - 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/6-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      6 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/6-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      6 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/7-7%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      7 - 7
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/7-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      7 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/7-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      7 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/8-8%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      8 - 8
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/8-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      8 - 9
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ekkochurch.com/wp-content/uploads/Advance/Marriage/Pairings/9-9%20Pairing_Schneider.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      9 - 9
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Read the chapter specific to your relationship (both of your
                Types in a relationship—for example, the Two-Four Couple).
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Response</h2>
            <ol>
              <li>
                Write a 250 word response to the readings and submit them to the
                Google Group by Wednesday at 11:59PM.
              </li>
              <li>
                Respond to another post as well as responses to your own post by
                Friday at 5:00PM.
              </li>
              <li>Have your Examens ready to show at Advance.</li>
            </ol>
            <h2 className={Styles.modal_subtitle}>In-Class Activity</h2>
            <ol>
              <li>Start the couple’s credo.</li>
              <li>Discuss draft 1 of couple’s credo with a pastor.</li>
              <li>
                Determine and finalize the credo for wedding ceremony purposes.
              </li>
            </ol>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Marriage;
