import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeroParenting from '../image-components/hero/heroParenting';
import Styles from './content.module.css';

const Parenting = () => {
  const [showWeek1, setWeek1] = useState(false);
  const [showWeek2, setWeek2] = useState(false);
  const [showWeek3, setWeek3] = useState(false);
  const [showWeek4, setWeek4] = useState(false);
  const [showWeek5, setWeek5] = useState(false);
  const [showWeek6, setWeek6] = useState(false);
  const [showFilesModal, setFilesModal] = useState(false);

  const modalClose = () => {
    setWeek1(false);
    setWeek2(false);
    setWeek3(false);
    setWeek4(false);
    setWeek5(false);
    setWeek6(false);
    setFilesModal(false);
  };

  return (
    <>
      <HeroParenting />
      <div className={Styles.container}>
        <div className={Styles.title}>PARENTING</div>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <a
              href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Parenting%20Syllabus%20F2020.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={Styles.syllabuscard}>SYLLABUS</button>
            </a>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek1(true)}>
              WEEK 1
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek2(true)}>
              WEEK 2
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek3(true)}>
              WEEK 3
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek4(true)}>
              WEEK 4
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek5(true)}>
              WEEK 5
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek6(true)}>
              WEEK 6
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button
              className={Styles.syllabuscard}
              onClick={() => setFilesModal(true)}
            >
              FILES
            </button>
          </Col>
        </Row>

        <Modal
          visible={showFilesModal}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>FILES</div>
          <div className={Styles.modal_body}>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Calendar.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Calendar Worksheet
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Seasonal%20Calendar.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EKKO Seasonal Calendar (Rhythms of Liturgy)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Family%20Credo%20Template.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Family Credo Template
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Values%20Worksheet.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Family Values Worksheet
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Sabbath%20Worksheet.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sabbath Worksheet
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Spokes%20Diagram.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Spokes Diagram
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Vocational%20Credo%20Template.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vocational Credo Template
                </a>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek1}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 1</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>Godly Parenting</p>
            <video width="100%" controls>
              <source
                src="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/Parenting%20-%20Meeting%201.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/1_GospelCentered_1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Calling" by Paul Tripp (from Parenting)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Parenting/1_GospelCentered_5.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Identity" by Paul Tripp (from Parenting)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a 250 word response to the readings and post online to the{' '}
              <a
                href="https://groups.google.com/g/2020parenting-advance"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Group
              </a>
              .
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek2}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 2</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>Grace</p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Read "Grace" by Paul Tripp (from Parenting)</li>
              <li>Read "Law" by Paul Tripp (from Parenting)</li>
              <li>Read "Inability" by Paul Tripp (from Parenting)</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a 250 word response to the readings and post online to the{' '}
              <a
                href="https://groups.google.com/g/2020parenting-advance"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Group
              </a>
              .
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek3}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 3</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>Creating God-Awareness</p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Read "Lost" by Paul Tripp (from Parenting)</li>
              <li>Read "Character" by Paul Tripp (from Parenting)</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a 250 word response to the readings and post online to the{' '}
              <a
                href="https://groups.google.com/g/2020parenting-advance"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Group
              </a>
              .
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek4}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 4</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>Family Credo</p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Read "Foolishness" by Paul Tripp (from Parenting)</li>
              <li>Read "False Gods" by Paul Tripp (from Parenting)</li>
              <li>Read "Control" by Paul Tripp (from Parenting)</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a 250 word response to the readings and post online to the{' '}
              <a
                href="https://groups.google.com/g/2020parenting-advance"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Group
              </a>
              .
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek5}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 5</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>Creating Healthy God-Submission</p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Read "Authority" by Paul Tripp (from Parenting)</li>
              <li>Read "Mercy" by Paul Tripp (from Parenting)</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a 250 word response to the readings and post online to the{' '}
              <a
                href="https://groups.google.com/g/2020parenting-advance"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Group
              </a>
              .
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek6}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 6</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>Long View of Parenting</p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Read "Process" by Paul Tripp (from Parenting)</li>
              <li>Read "Rest" by Paul Tripp (from Parenting)</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a 250 word response to the readings and post online to the{' '}
              <a
                href="https://groups.google.com/g/2020parenting-advance"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Group
              </a>
              .
            </p>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Parenting;
