import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeroVocation from '../image-components/hero/heroVocation';
import Styles from './content.module.css';

const Vocation = () => {
  const [showWeek1, setWeek1] = useState(false);
  const [showWeek2, setWeek2] = useState(false);
  const [showWeek3, setWeek3] = useState(false);
  const [showWeek4, setWeek4] = useState(false);
  const [showWeek5, setWeek5] = useState(false);
  const [showWeek6, setWeek6] = useState(false);

  const modalClose = () => {
    setWeek1(false);
    setWeek2(false);
    setWeek3(false);
    setWeek4(false);
    setWeek5(false);
    setWeek6(false);
  };
  return (
    <>
      <HeroVocation />
      <div className={Styles.container}>
        <div className={Styles.title}>VOCATION DASHBOARD</div>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <a
              href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/Vocation%20Advance%20Syllabus%20S2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={Styles.syllabuscard}>SYLLABUS</button>
            </a>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek1(true)}>
              WEEK 1
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek2(true)}>
              WEEK 2
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek3(true)}>
              WEEK 3
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek4(true)}>
              WEEK 4
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek5(true)}>
              WEEK 5
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek6(true)}>
              WEEK 6
            </button>
          </Col>
        </Row>

        <Modal
          visible={showWeek1}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 1</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Objectives</h2>
            <ol>
              <li>Find out who is in the room: Introductions</li>
              <li>Review syllabus</li>
              <li>
                Develop a working knowledge of critical thinking and spiritual
                formation
              </li>
              <li>Acquire a beginning understanding of a "Why"</li>
            </ol>
            <h2 className={Styles.modal_subtitle}>Class Plan</h2>
            <ul>
              <li>
                Hour One: Introductions, syllabus review, critical thinking and
                spiritual formation and the role they play in vocational
                discernment
              </li>
              <li>
                Hour Two: Developing a definition of vocation: The importance of
                your "Why"
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_Intro.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Introduction" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Why Vocation Matters" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "What is Vocation Anyway?" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek2}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 2</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Objectives</h2>
            <ol>
              <li>Discovering your own personal values story</li>
              <li>Learning values from Esther, Joseph</li>
              <li>Exploring the edges of our own doubt story</li>
              <li>Demonstrating the importance of work: your work story</li>
            </ol>
            <h2 className={Styles.modal_subtitle}>Class Plan</h2>
            <ul>
              <li>
                Hour One: Read "The Way It Is" and discuss Discover Your Values
              </li>
              <li>Hour Two: Map your work experience</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>Read the book of Esther</li>
              <li>Read the story of Joseph (Genesis 37-45:8)</li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek3}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 3</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Objectives</h2>
            <ol>
              <li>Uncovering the motivations for Vocation</li>
              <li>Discover God’s gift of story</li>
              <li>
                Engage with inspiring people to find what they can teach us
                about vocation
              </li>
            </ol>
            <h2 className={Styles.modal_subtitle}>Class Plan</h2>
            <ul>
              <li>
                Hour One: Your passion story, how opposition makes us stronger
              </li>
              <li>Hour Two: Create Personal life story maps</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_3.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "How Pain Sets the Trajectory for Vocation" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Illumination from Darkness" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_5.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Puzzle Pieces of Your Story" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek4}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 4</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Objectives</h2>
            <ol>
              <li>Discover how you would change the world</li>
              <li>Your healing story</li>
              <li>Exploring how opposition makes us stronger</li>
            </ol>
            <h2 className={Styles.modal_subtitle}>Class Plan</h2>
            <ul>
              <li>Hour One: Healing Story, Pushback, Heart burst</li>
              <li>Hour Two: Develop credo and share</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Dreams That Heal the World" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_7.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Creating Your Vocational Credo" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek5}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 5</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Objectives</h2>
            <ol>
              <li>Discovering your toxic skills</li>
              <li>Discovering your work style preferences</li>
              <li>
                Develop a working knowledge of critical thinking and spiritual
                formation
              </li>
              <li>
                Compare and contrast work experience with vocational preferences
              </li>
              <li>
                Mapping a new future from what you have learned about yourself
              </li>
            </ol>
            <h2 className={Styles.modal_subtitle}>Class Plan</h2>
            <ul>
              <li>Hour One: Toxic Skills</li>
              <li>
                Hour Two: Vocational Preference Grid, compare and contrast with
                your work experience
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_8.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Identifying Toxic Skills" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_9.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Addressing the Fear of Failure" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_10.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Pursuing Change & Chaos" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_11.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Discovering Your Vocational Preferences" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek6}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 6</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Objectives</h2>
            <ol>
              <li>
                Comprehend and integrate the idea of a personal Board of
                Directors
              </li>
              <li>
                Discover the place that work plays in the life of a follower of
                Jesus
              </li>
              <li>
                To unpack how we must be formed to get where we want to go
              </li>
            </ol>
            <h2 className={Styles.modal_subtitle}>Class Plan</h2>
            <ul>
              <li>Hour One: Patinas, Create personal board of directors</li>
              <li>Hour Two: Full integration of Vocational work: Tool Belt</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_12.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Leaving Behind a Legacy" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/VocationalCredo_13.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Giving Voice to Your Song" (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Your Vocational Credo
                  </span>
                  )
                </a>
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Vocation;
