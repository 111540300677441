import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeroSelah from '../image-components/hero/heroSelah';
import Styles from './content.module.css';

const Selah = () => {
  const [showWeek1, setWeek1] = useState(false);
  const [showWeek2, setWeek2] = useState(false);
  const [showWeek3, setWeek3] = useState(false);
  const [showWeek4, setWeek4] = useState(false);
  const [showWeek5, setWeek5] = useState(false);
  const [showWeek6, setWeek6] = useState(false);

  const modalClose = () => {
    setWeek1(false);
    setWeek2(false);
    setWeek3(false);
    setWeek4(false);
    setWeek5(false);
    setWeek6(false);
  };
  return (
    <>
      <HeroSelah />
      <div className={Styles.container}>
        <div className={Styles.title}>SELAH</div>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <a
              href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Selah%20Syllabus%20F2020.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={Styles.syllabuscard}>SYLLABUS</button>
            </a>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek1(true)}>
              WEEK 1
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek2(true)}>
              WEEK 2
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek3(true)}>
              WEEK 3
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek4(true)}>
              WEEK 4
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek5(true)}>
              WEEK 5
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek6(true)}>
              WEEK 6
            </button>
          </Col>
        </Row>

        <Modal
          visible={showWeek1}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 1</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Files</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Selah%20Advance_Prayers%20of%20Intention.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayers of Intention
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/LD%202020_Week%201.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lectio Divina - Week 1
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Summary</h2>
            <p className={Styles.subheading}>
              Book of Psalms gives us a rich and ongoing tradition and guidance
              on speaking to God as a people of faith.
            </p>
            <h2 className={Styles.modal_subtitle}>Keyword</h2>
            <p className={Styles.subheading}>Open</p>
            <h2 className={Styles.modal_subtitle}>
              Daily Prayers of Intention
            </h2>
            <p className={Styles.subheading}>
              Carve out 5 minutes in the morning to simply pray the words from
              the Prayers of Intention, showing up as fully and honestly as you
              are able.
            </p>
            <h2 className={Styles.modal_subtitle}>Daily Lectio Divina</h2>
            <p className={Styles.subheading}>
              Carve out 20 minutes Mon-Sat to go through the four movements of
              Lectio Divina.
            </p>
            <ul>
              <li>Wed: Psalm 1</li>
              <li>Thurs: Mattthew 16:13-17</li>
              <li>Fri: Matthew 3:13-17</li>
              <li>Sat: John 4:1-42</li>
              <li>Mon: Luke 5:1-11</li>
              <li>Tues: Mark 1:21-39</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Prayer Project</h2>
            <ul>
              <li>One day a week, carve out 30 minutes to be with God.</li>
              <li>
                Choose one day to reflect in the Spirit for 15 minutes as you to
                either 1) Sit in quiet, solitary place without music or 2) go
                for a walk. Ponder your week of being in the Word of God and
                prayer.
              </li>
              <li>
                What came up during that time? What were some recurring
                emotions, thoughts, images and experiences? How was your time of
                exercising the KEYWORD? How do you sense God in your time right
                now?
              </li>
              <li>
                Then, take another 15 minutes to write an honest reflection
                based on your reflection of the week. Write up to a page,
                single-spaced.{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Email response to Joanne, Priscilla, and Taylor by 11:59pm on
                  Saturday.
                </span>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek2}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 2</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Files</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Selah%20Advance_Prayers%20of%20Intention.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayers of Intention
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/LD%202020_Week%202.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lectio Divina - Week 2
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Summary</h2>
            <p className={Styles.subheading}>
              We no longer have to cover and hide; we can come to God because
              the finished work of Christ in his death and resurrection.
            </p>
            <h2 className={Styles.modal_subtitle}>Keyword</h2>
            <p className={Styles.subheading}>
              No condemnation, good information!
            </p>
            <h2 className={Styles.modal_subtitle}>
              Daily Prayers of Intention
            </h2>
            <p className={Styles.subheading}>
              Carve out 5 minutes in the morning to simply pray the words from
              the Prayers of Intention, showing up as fully and honestly as you
              are able.
            </p>
            <h2 className={Styles.modal_subtitle}>Daily Lectio Divina</h2>
            <p className={Styles.subheading}>
              Carve out 20 minutes Mon-Sat to go through the four movements of
              Lectio Divina.
            </p>
            <ul>
              <li>Wed: Psalm 63</li>
              <li>Thurs: Luke 7:36-50</li>
              <li>Fri: Mark 4:35-41</li>
              <li>Sat: Mark 5:25-34</li>
              <li>Mon: Mark 6:30-44</li>
              <li>Tues: Matthew 14:22-33</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Prayer Project</h2>
            <ul>
              <li>One day a week, carve out 30 minutes to be with God.</li>
              <li>
                Choose one day to reflect in the Spirit for 15 minutes as you to
                either 1) Sit in quiet, solitary place without music or 2) go
                for a walk. Ponder your week of being in the Word of God and
                prayer.
              </li>
              <li>
                What came up during that time? What were some recurring
                emotions, thoughts, images and experiences? How was your time of
                exercising the KEYWORD? How do you sense God in your time right
                now?
              </li>
              <li>
                Then, take another 15 minutes to write an honest reflection
                based on your reflection of the week. Write up to a page,
                single-spaced.{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Email response to Joanne, Priscilla, and Taylor by 11:59pm on
                  Saturday.
                </span>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek3}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 3</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Files</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Selah%20Advance_Prayers%20of%20Intention.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayers of Intention
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/LD%202020_Week%203.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lectio Divina - Week 3
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Summary</h2>
            <p className={Styles.subheading}>
              God is always with us through the Holy Spirit. Nothing can
              separate us from God’s love. He is always with us in all the
              moments and images and sounds and experiences, if we will pay
              attention through "divine seeing."
            </p>
            <h2 className={Styles.modal_subtitle}>Keyword</h2>
            <p className={Styles.subheading}>Invitation</p>
            <h2 className={Styles.modal_subtitle}>
              Daily Prayers of Intention
            </h2>
            <p className={Styles.subheading}>
              Carve out 5 minutes in the morning to simply pray the words from
              the Prayers of Intention, showing up as fully and honestly as you
              are able.
            </p>
            <h2 className={Styles.modal_subtitle}>Daily Lectio Divina</h2>
            <p className={Styles.subheading}>
              Carve out 20 minutes Mon-Sat to go through the four movements of
              Lectio Divina.
            </p>
            <ul>
              <li>Wed: Psalm 39</li>
              <li>Thurs: Mark 10:17-31</li>
              <li>Fri: Mark 9:30-37</li>
              <li>Sat: John 10:7-18</li>
              <li>Mon: Luke 10:38-42</li>
              <li>Tues: John 11:1-44</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Prayer Project</h2>
            <ul>
              <li>One day a week, carve out 30 minutes to be with God.</li>
              <li>
                Choose one day to reflect in the Spirit for 15 minutes as you to
                either 1) Sit in quiet, solitary place without music or 2) go
                for a walk. Ponder your week of being in the Word of God and
                prayer.
              </li>
              <li>
                What came up during that time? What were some recurring
                emotions, thoughts, images and experiences? How was your time of
                exercising the KEYWORD? How do you sense God in your time right
                now?
              </li>
              <li>
                Then, take another 15 minutes to write an honest reflection
                based on your reflection of the week. Write up to a page,
                single-spaced.{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Email response to Joanne, Priscilla, and Taylor by 11:59pm on
                  Saturday.
                </span>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek4}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 4</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Files</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Selah%20Advance_Prayers%20of%20Intention.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayers of Intention
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/LD%202020_Week%204.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lectio Divina - Week 4
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Summary</h2>
            <p className={Styles.subheading}>Review Weeks 1-3.</p>
            <h2 className={Styles.modal_subtitle}>
              Daily Prayers of Intention
            </h2>
            <p className={Styles.subheading}>
              Carve out 5 minutes in the morning to simply pray the words from
              the Prayers of Intention, showing up as fully and honestly as you
              are able.
            </p>
            <h2 className={Styles.modal_subtitle}>Daily Lectio Divina</h2>
            <p className={Styles.subheading}>
              Carve out 20 minutes Mon-Sat to go through the four movements of
              Lectio Divina.
            </p>
            <ul>
              <li>Wed: Psalm 100</li>
              <li>Thurs: John 8:1-11</li>
              <li>Fri: Mark 10:46-52</li>
              <li>Sat: Luke 19:1-10</li>
              <li>Mon: Luke 15:11-32</li>
              <li>Tues: Matthew 20:20-28</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Prayer Project</h2>
            <ul>
              <li>One day a week, carve out 30 minutes to be with God.</li>
              <li>
                Choose one day to reflect in the Spirit for 15 minutes as you to
                either 1) Sit in quiet, solitary place without music or 2) go
                for a walk. Ponder your week of being in the Word of God and
                prayer.
              </li>
              <li>
                What came up during that time? What were some recurring
                emotions, thoughts, images and experiences? How was your time of
                exercising the KEYWORD? How do you sense God in your time right
                now?
              </li>
              <li>
                Then, take another 15 minutes to write an honest reflection
                based on your reflection of the week. Write up to a page,
                single-spaced.{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Email response to Joanne, Priscilla, and Taylor by 11:59pm on
                  Saturday.
                </span>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek5}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 5</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Files</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Selah%20Advance_Prayers%20of%20Intention.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayers of Intention
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Prayer%20of%20Examen_2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Examen Overview
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Prayer%20of%20Examen%201.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Examen - 1 (Praying for Light)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Prayer%20of%20Examen%202.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Examen - 2 (Giving Thanks)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Prayer%20of%20Examen%203.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Examen - 3 (Reviewing the Day)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Prayer%20of%20Examen%204.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Examen - 4 (Facing What's Wrong)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Prayer%20of%20Examen%205.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Examen - 5 (Doing Something- But Not Just Anything)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Summary</h2>
            <p className={Styles.subheading}>
              The Prayer of Examen assists in the challenge to find God in the
              midst of our daily lives. God is reaching out to make a home with
              us as we share our experiences with Him and find His presence in
              all parts of our day.
            </p>
            <h2 className={Styles.modal_subtitle}>Keyword</h2>
            <p className={Styles.subheading}>TBD</p>
            <h2 className={Styles.modal_subtitle}>
              Daily Prayers of Intention
            </h2>
            <p className={Styles.subheading}>
              Carve out 5 minutes in the morning to simply pray the words from
              the Prayers of Intention, showing up as fully and honestly as you
              are able.
            </p>
            <h2 className={Styles.modal_subtitle}>Daily Lectio Divina</h2>
            <p className={Styles.subheading}>
              Carve out 20 minutes Mon-Sat to go through the four movements of
              Lectio Divina.
            </p>
            <ul>
              <li>Wed: Psalm 139</li>
              <li>Thurs: Matthew 25:31-40</li>
              <li>Fri: John 12:1-8</li>
              <li>Sat: John 13:1-17</li>
              <li>Mon: Luke 22:14-20</li>
              <li>Tues: Matthew 26:36-46</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Daily Prayer of Examen</h2>
            <p className={Styles.subheading}>
              Set aside 20 minutes for this prayer.
            </p>
            <h2 className={Styles.modal_subtitle}>Prayer Project</h2>
            <ul>
              <li>One day a week, carve out 30 minutes to be with God.</li>
              <li>
                Choose one day to reflect in the Spirit for 15 minutes as you to
                either 1) Sit in quiet, solitary place without music or 2) go
                for a walk. Ponder your week of being in the Word of God and
                prayer.
              </li>
              <li>
                What came up during that time? What were some recurring
                emotions, thoughts, images and experiences? How was your time of
                exercising the KEYWORD? How do you sense God in your time right
                now?
              </li>
              <li>
                Then, take another 15 minutes to write an honest reflection
                based on your reflection of the week. Write up to a page,
                single-spaced.{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Email response to Joanne, Priscilla, and Taylor by 11:59pm on
                  Saturday.
                </span>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek6}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 6</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Files</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Selah%20Advance_Prayers%20of%20Intention.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayers of Intention
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Centering%20Prayer%202020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Centering Prayer
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Selah/Unpacking%20the%20Gifts_2020.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Unpacking the Gifts
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Summary</h2>
            <p className={Styles.subheading}>
              God is central and we find ourselves called into his presence to
              know the freedom – not the freedom that comes when everything
              falls into place just right—but of being beloved regardless. We
              believe God is present and God invites us to rest in Him.
            </p>
            <h2 className={Styles.modal_subtitle}>Keyword</h2>
            <p className={Styles.subheading}>Return</p>
            <h2 className={Styles.modal_subtitle}>
              Daily Prayers of Intention
            </h2>
            <p className={Styles.subheading}>
              Carve out 5 minutes in the morning to simply pray the words from
              the Prayers of Intention, showing up as fully and honestly as you
              are able.
            </p>
            <h2 className={Styles.modal_subtitle}>Daily Lectio Divina</h2>
            <p className={Styles.subheading}>
              Carve out 20 minutes Mon-Sat to go through the four movements of
              Lectio Divina.
            </p>
            <ul>
              <li>Wed: Psalm 131</li>
              <li>Thurs: Luke 23:33-47</li>
              <li>Fri: Matthew 26:36-46</li>
              <li>Sat: John 20:1-18</li>
              <li>Mon: Luke 24:13-34</li>
              <li>Tues: Matthew 28:16-20</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Daily Centering Prayer</h2>
            <ol>
              <li>Pick a word that describes God to you.</li>
              <li>
                Be in God’s presence for 20 minutes, being attentive to God in
                silence.
              </li>
              <li>
                When thoughts, emotions and images come into your notice, simply
                return to God by saying aloud the word you chose to describe
                God.
              </li>
            </ol>
            <h2 className={Styles.modal_subtitle}>Prayer Project</h2>
            <ul>
              <li>One day a week, carve out 30 minutes to be with God.</li>
              <li>
                Choose one day to reflect in the Spirit for 15 minutes as you to
                either 1) Sit in quiet, solitary place without music or 2) go
                for a walk. Ponder your week of being in the Word of God and
                prayer.
              </li>
              <li>
                What came up during that time? What were some recurring
                emotions, thoughts, images and experiences? How was your time of
                exercising the KEYWORD? How do you sense God in your time right
                now?
              </li>
              <li>
                Then, take another 15 minutes to write an honest reflection
                based on your reflection of the week. Write up to a page,
                single-spaced.{' '}
                <span style={{ fontWeight: 'bold' }}>
                  Email response to Joanne, Priscilla, and Taylor by 11:59pm on
                  Saturday.
                </span>
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Selah;
