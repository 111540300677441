import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeroLogos from '../image-components/hero/heroLogos';
import Styles from './content.module.css';

const Pneuma = () => {
  const [showWeek1, setWeek1] = useState(false);
  const [showWeek2, setWeek2] = useState(false);
  const [showWeek3, setWeek3] = useState(false);
  const [showWeekE, setWeekE] = useState(false);
  const [showWeek4, setWeek4] = useState(false);
  const [showWeek5, setWeek5] = useState(false);
  const [showWeek6, setWeek6] = useState(false);

  const modalClose = () => {
    setWeek1(false);
    setWeek2(false);
    setWeek3(false);
    setWeekE(false);
    setWeek4(false);
    setWeek5(false);
    setWeek6(false);
  };
  return (
    <>
      <HeroLogos />
      <div className={Styles.container}>
        <div className={Styles.title}>LOGOS DASHBOARD</div>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <a
              href="https://ekkochurch.com/wp-content/uploads/Advance/Logos/Logos%20Syllabus%20S2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={Styles.syllabuscard}>SYLLABUS</button>
            </a>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek1(true)}>
              WEEK 1
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek2(true)}>
              WEEK 2
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek3(true)}>
              WEEK 3
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button
              className={Styles.syllabuscard}
              onClick={() => setWeekE(true)}
            >
              EKKLESIA WEEK
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek4(true)}>
              WEEK 4
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek5(true)}>
              WEEK 5
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek6(true)}>
              WEEK 6
            </button>
          </Col>
        </Row>

        <Modal
          visible={showWeek1}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 1</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              The Bible As Scripture & Story. The Beginning of the Story:
              Genesis-Deuteronomy
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Genesis 1-33</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <ul>
              <li>
                <p>
                  Memorize all the books of the OT in order (quiz next week).
                  This song will help!
                </p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/XAFopUtR4c4"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <div>Daily Devotionals</div>
                <ol>
                  <li>Monday: Gen 1-2</li>
                  <li>Tuesday: Gen 3-4</li>
                  <li>Wednesday: Gen 5-6</li>
                  <li>Thursday: Gen 7-8</li>
                  <li>Friday: Gen 9-10</li>
                </ol>
              </li>
              <li>
                <p>Watch "What is the Bible?" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/ak06MSETeo4"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek2}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 2</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              The Story of Israel In (And Out Of) The Land: Joshua-Esther
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Judges 1-21</li>
              <li>Ruth 1-4</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <ul>
              <li>
                <div>Daily Devotionals</div>
                <ol>
                  <li>Monday: Esther 1-2</li>
                  <li>Tuesday: Esther 3-4</li>
                  <li>Wednesday: Esther 5-6</li>
                  <li>Thursday: Esther 7-8</li>
                  <li>Friday: Esther 9-10</li>
                </ol>
              </li>
              <li>
                <p>Watch "The Story of the Bible" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/7_CGP-12AE0"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <p>Watch "Character in the Bible" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/0EQDGax19xk"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek3}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 3</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <div className={Styles.subheading}>
              The Witness of Israel’s Poets and Sages: Job-Song of Songs
            </div>
            <div className={Styles.subheading}>
              The Witness of Israel’s Prophets: Isaiah-Malachi
            </div>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Proverbs 1-31</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <ul>
              <li>
                <p>
                  Memorize all the books of the NT in order (quiz in two weeks).
                  This song will help!
                </p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/tbSgW5ty5ow"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <div>Daily Devotionals</div>
                <ol>
                  <li>Monday: Psalm 1</li>
                  <li>Tuesday: Psalm 42</li>
                  <li>Wednesday: Psalm 22</li>
                  <li>Thursday: Psalm 51</li>
                  <li>Friday: Psalm 103</li>
                </ol>
              </li>
              <li>
                <p>Watch "The Art of Biblical Poetry" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/q9yp1ZXbsEg"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeekE}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>EKKLESIA WEEK</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              No Advance! (Ekklesia on February 26)
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Ecclesiastes 1-12</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <ul>
              <li>
                <p>
                  Memorize all the books of the NT in order (quiz next week).
                  This song will help!
                </p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/XAFopUtR4c4"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/tbSgW5ty5ow"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <div>Daily Devotionals</div>
                <ol>
                  <li>Monday: Lamentations 1</li>
                  <li>Tuesday: Lamentations 2</li>
                  <li>Wednesday: Lamentations 3</li>
                  <li>Thursday: Lamentations 4</li>
                  <li>Friday: Lamentations 5</li>
                </ol>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek4}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 4</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              The Story of Jesus: The Four Gospels
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Matthew 1-28</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <ul>
              <li>
                <div>Daily Devotionals</div>
                <ol>
                  <li>Monday: Matthew 1-2</li>
                  <li>Tuesday: Matthew 3-4</li>
                  <li>Wednesday: Matthew 5</li>
                  <li>Thursday: Matthew 6</li>
                  <li>Friday: Matthew 7</li>
                </ol>
              </li>
              <li>
                <p>
                  Watch "Setting in Biblical Narrative" by The Bible Project
                </p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/7FuT8WtoAK0"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <p>Watch "Gospel of the Kingdom" by The Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/xmFPS0f-kzs"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek5}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 5</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              The Story of The Church: Acts & The Letters
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Romans</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <ul>
              <li>
                <div>Daily Devotionals</div>
                <ol>
                  <li>Monday: Philippians 1</li>
                  <li>Tuesday: Philippians 2</li>
                  <li>Wednesday: Philippians 3</li>
                  <li>Thursday: Philippians 4</li>
                  <li>Friday: Re-read Philippians in one sitting</li>
                </ol>
              </li>
              <li>
                <p>Watch "Sacrifice and Atonement" by The Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/G_OlRWGLdnw"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <p>Watch "Holiness" by The Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/l9vn5UvsHvM"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek6}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 6</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              The End of the Story: Revelation
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>Revelation 1-22</li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <ul>
              <li>
                <div>Daily Devotionals</div>
                <ol>
                  <li>Monday: Revelation 1</li>
                  <li>Tuesday: Revelation 2</li>
                  <li>Wednesday: Revelation 3</li>
                  <li>Thursday: Revelation 4</li>
                  <li>Friday: Revelation 5</li>
                </ol>
              </li>
              <li>
                <p>Watch "Day of the Lord" by The Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/tEBc2gSSW04"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <p>Watch "Justice" by The Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/A14THPoc4-4"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Pneuma;
