import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeroWork from '../image-components/hero/heroWork';
import Styles from './content.module.css';

const Work = () => {
  const [showWeek1, setWeek1] = useState(false);
  const [showWeek2, setWeek2] = useState(false);
  const [showWeek3, setWeek3] = useState(false);
  const [showWeek4, setWeek4] = useState(false);
  const [showWeek5, setWeek5] = useState(false);
  const [showWeek6, setWeek6] = useState(false);
  const [showFiles, setFilesModal] = useState(false);

  const modalClose = () => {
    setWeek1(false);
    setWeek2(false);
    setWeek3(false);
    setWeek4(false);
    setWeek5(false);
    setWeek6(false);
    setFilesModal(false);
  };
  return (
    <>
      <HeroWork />
      <div className={Styles.container}>
        <div className={Styles.title}>WORK</div>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <a
              href="https://ekkochurch.com/wp-content/uploads/Advance/Work/Work%20Syllabus%20F2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={Styles.syllabuscard}>SYLLABUS</button>
            </a>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek1(true)}>
              WEEK 1
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek2(true)}>
              WEEK 2
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek3(true)}>
              WEEK 3
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek4(true)}>
              WEEK 4
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek5(true)}>
              WEEK 5
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek6(true)}>
              WEEK 6
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button
              className={Styles.syllabuscard}
              onClick={() => setFilesModal(true)}
            >
              FILES
            </button>
          </Col>
        </Row>

        <Modal
          visible={showWeek1}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 1</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              <a
                href="https://ekkochurch.com/wp-content/uploads/Advance/Work/_Scripture/WK01%20Scripture.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Genesis 1 | The Origins of Calling (Caller / Creation)
              </a>
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/01_/bf447e28d0caed73-Welcometotheartofbeinghuman.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Welcome to the art of being human" by John Mark Comer
                  (from Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/01_/d358a0309bd10f98-GardenCity_1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Kings and queens" by John Mark Comer (from Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/01_/9ec08e899fedf1a1-GardenCity_2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "A place called Delight" by John Mark Comer (from Garden
                  City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/01_/8185c063f50ad0d6-GardenCity_3.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The unearthing of a calling" by John Mark Comer (from
                  Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/01_/56bf5ddea890b95a-GardenCity_4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Everything is spiritual" by John Mark Comer (from Garden
                  City)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a reflection on the reading.
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek2}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 2</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              <a
                href="https://ekkochurch.com/wp-content/uploads/Advance/Work/_Scripture/WK02%20Scripture.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ezekiel 37 | The Hope of Calling (Spirit Led Imagination)
              </a>
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/02_/d6c480bd2a889d5f-Kavoid.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Kavod" by John Mark Comer (from Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/02_/b49b9d19a6740756-KazamMachine.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Kazam! Machine" by John Mark Comer (from Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/02_/1d3930dd0443c8e7-Cursedistheground.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Cursed is the ground" by John Mark Comer (from Garden
                  City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/02_/8ecfd3c741a8f9de-Iamnotamachine.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "I am not a machine" by John Mark Comer (from Garden
                  City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/02_/60eeafad0d22ec9b-Theanti-Pharaoh.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The anti-Pharaoh" by John Mark Comer (from Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/02_/1be668774933180c-TheLordoftheSabbath.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Lord of the Sabbath" by John Mark Comer (from Garden
                  City)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a reflection on the reading.
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek3}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 3</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              <a
                href="https://ekkochurch.com/wp-content/uploads/Advance/Work/_Scripture/WK03%20Scripture.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jeremiah 29 | The Context of Calling (Exile)
              </a>
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/03_/88a9f42a95de62f5-Lifeafterheaven.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Life after heaven" by John Mark Comer (from Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/03_/902128196b8bb65b-Thepeopleofthefuture.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The people of the future" by John Mark Comer (from
                  Garden City)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/03_/a36b3f66938ef2cc-Epilogue.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Epilogue: Redefining greatness" by John Mark Comer (from
                  Garden City)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a reflection on the reading.
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek4}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 4</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              <a
                href="https://ekkochurch.com/wp-content/uploads/Advance/Work/_Scripture/WK04%20Scripture.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Isaiah 6 | The Purpose of Calling (Glory)
              </a>
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/04_/a3c6226199aa122d-CAW_Intro.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Introduction" by Todd Hunter (from Our Character at
                  Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/04_/fcf17f4262fd7483-CAW_1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Reality, Revelation, & Power" by Todd Hunter (from Our
                  Character at Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/04_/056498e00bf1e574-CAW_2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Inward Journey" by Todd Hunter (from Our Character
                  at Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/04_/f3c048d99b74a86a-CAW_3.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Heart of the Matter" by Todd Hunter (from Our
                  Character at Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/04_/ce26dca51c31d6cf-CAW_4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Training the Character" by Todd Hunter (from Our
                  Character at Work)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a reflection on the reading.
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek5}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 5</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              <a
                href="https://ekkochurch.com/wp-content/uploads/Advance/Work/_Scripture/WK05%20Scripture.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                1 Corinthians 2 | The Discernment Needed for Calling (Wisdom)
              </a>
            </p>
            <h2 className={Styles.modal_subtitle}>Readings</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/05_/228f3d366df44227-CAW_5.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Meeting Demands" by Todd Hunter (from Our Character at
                  Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/05_/97075662e1cc040a-CAW_6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Heart Trouble" by Todd Hunter (from Our Character at
                  Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/05_/df9a5a55d008eeb5-CAW_7.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "An Audience of One" by Todd Hunter (from Our Character
                  at Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/05_/8e0fc5d3167aec82-CAW_8.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Nature of Servant Leadership" by Todd Hunter (from
                  Our Character at Work)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/05_/CAW_9.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Making the Decision" by Todd Hunter (from Our Character
                  at Work)
                </a>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>Assignments</h2>
            <p className={Styles.subheading}>
              Write a reflection on the reading.
            </p>
          </div>
        </Modal>
        <Modal
          visible={showWeek6}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 6</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              <a
                href="https://ekkochurch.com/wp-content/uploads/Advance/Work/_Scripture/WK06%20Scripture.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Isaiah 60 & Revelation 21 | The Fulfillment of Calling (New
                Jerusalem)
              </a>
            </p>
          </div>
        </Modal>
        <Modal
          visible={showFiles}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>FILES</div>
          <div className={Styles.modal_body}>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/Prayer%20of%20Examen.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Examen
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/Prayer%20of%20Discernment.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Prayer of Discernment
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/Hope%20Worksheet.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hope Worksheet
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Work/Drawing%20Prayer.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Drawing Prayer
                </a>
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Work;
