import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeroDoulos from '../image-components/hero/heroDoulos';
import Styles from './content.module.css';

const Doulos = () => {
  const [showWeek1, setWeek1] = useState(false);
  const [showWeek2, setWeek2] = useState(false);
  const [showWeek3, setWeek3] = useState(false);
  const [showWeek4, setWeek4] = useState(false);
  const [showWeek5, setWeek5] = useState(false);
  const [showWeek6, setWeek6] = useState(false);

  const modalClose = () => {
    setWeek1(false);
    setWeek2(false);
    setWeek3(false);
    setWeek4(false);
    setWeek5(false);
    setWeek6(false);
  };
  return (
    <>
      <HeroDoulos />
      <div className={Styles.container}>
        <div className={Styles.title}>DOULOS DASHBOARD</div>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <a
              href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Doulos%20Advance%20Syllabus%20S2021.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={Styles.syllabuscard}>SYLLABUS</button>
            </a>
          </Col>
          {JSON.parse(
            localStorage.getItem('advanceUser')
          ).email.toUpperCase() === 'ISAAC.CHONG@EKKOCHURCH.COM' && (
            <>
              <Col md={7} sm={20} xs={20}>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Vocation/tmp.gif"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={Styles.syllabuscard}>
                    FOR PASTOR ISAAC
                  </button>
                </a>
              </Col>
            </>
          )}
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek1(true)}>
              WEEK 1
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek2(true)}>
              WEEK 2
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek3(true)}>
              WEEK 3
            </button>
          </Col>
        </Row>
        <Row className={Styles.row} justify="space-around">
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek4(true)}>
              WEEK 4
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek5(true)}>
              WEEK 5
            </button>
          </Col>
          <Col md={7} sm={20} xs={20}>
            <button className={Styles.weekcard} onClick={() => setWeek6(true)}>
              WEEK 6
            </button>
          </Col>
        </Row>

        <Modal
          visible={showWeek1}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 1</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>REPRESENTATIVES OF GOD</p>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Garden%20City_1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Kings and queens" by John Mark Comer (from{' '}
                  <span style={{ 'font-style': 'italic' }}>Garden City</span>)
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Garden%20City_2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "A place called delight" by John Mark Comer (from{' '}
                  <span style={{ 'font-style': 'italic' }}>Garden City</span>)
                </a>
              </li>
              <li>
                <p>Watch "Image of God" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/YbipxLDtY8c"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek2}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 2</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              THE NEW EGYPT & THE STORIES WE TELL
            </p>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Jesus%20Wants%20to%20Save%20Christians_1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Cry of the Oppressed" by Rob Bell & Dan Golden (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Jesus Wants to Save Christians
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Overrated_2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Why We Do Justice" by Eugene Cho (from{' '}
                  <span style={{ 'font-style': 'italic' }}>Overrated</span>)
                </a>
              </li>
              <li>
                <p>Watch "Exile" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/xSua9_WhQFE"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
            <h2 className={Styles.modal_subtitle}>PRACTICAL OUTWORKING</h2>
          </div>
        </Modal>
        <Modal
          visible={showWeek3}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 3</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>ABRAHAMIC BLESSING</p>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Jesus%20Wants%20to%20Save%20Christians_4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Genital-Free Africans" by Rob Bell & Dan Golden (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Jesus Wants to Save Christians
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Overrated_9.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Irony of Doing Justice... Unjustly" by Eugene Cho
                  (from{' '}
                  <span style={{ 'font-style': 'italic' }}>Overrated</span>)
                </a>
              </li>
              <li>
                <p>Watch "Generosity" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/62CliEkRCso"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek4}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 4</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>PROPHETIC JUSTICE (ELIJAH SUNG)</p>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Generous%20Justice_1_new.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "What is Doing Justice?" by Timothy Keller (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Generous Justice
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Generous%20Justice_3_new.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "What Did Jesus Say About Justice?" by Timothy Keller
                  (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    Generous Justice
                  </span>
                  )
                </a>
              </li>
              <li>
                <p>Watch "Justice" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/A14THPoc4-4"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek5}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 5</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>
              JERUSALEM, JUDEA, SAMARIA, AND THE ENDS OF THE EARTH
            </p>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Hole%20in%20Our%20Gospel_24.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "How Many Loaves Do You Have?" by Richard Stearns (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    The Hole in Our Gospel
                  </span>
                  )
                </a>
              </li>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Hole%20in%20Our%20Gospel_25.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "Time, Talent, and Treasure" by Richard Stearns (from{' '}
                  <span style={{ 'font-style': 'italic' }}>
                    The Hole in Our Gospel
                  </span>
                  )
                </a>
              </li>
              <li>
                <p>Watch "Holiness" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/l9vn5UvsHvM"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
        <Modal
          visible={showWeek6}
          onCancel={modalClose}
          closable={false}
          footer={null}
        >
          <div className={Styles.modal_title}>WEEK 6</div>
          <div className={Styles.modal_body}>
            <h2 className={Styles.modal_subtitle}>Lecture</h2>
            <p className={Styles.subheading}>THE GARDEN & THE CITY</p>
            <h2 className={Styles.modal_subtitle}>Reading Assignments</h2>
            <ul>
              <li>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Overrated_10.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read "The Best Part of Wanting to Change the World" by Eugene
                  Cho (from{' '}
                  <span style={{ 'font-style': 'italic' }}>Overrated</span>)
                </a>
              </li>
              <li>
                <p>Watch "Heaven & Earth" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/Zy2AQlK6C5k"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
              <li>
                <p>Watch "The New Humanity" by the Bible Project</p>
                <iframe
                  width="100%"
                  height="200"
                  src="https://www.youtube.com/embed/takEeHtRrMw"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </li>
            </ul>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Doulos;
